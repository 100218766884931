$color-theme_01-01 : #005961; // Green
$color-theme_01-02 : #022229; // Dark green
$color-theme_01-03 : #9ABBBE; // Light green

$colour-tertiary: $color-theme_01-03;
$colour-available: rgba($colour-tertiary, .5);

.prologue-boat,
.prologue-windmill {
	display:none !important;
}
.newsletter-boat {
	bottom:-7px;
	width:auto;
	height:280px;
}

h1, h2, h3, h4, h5, h6,
.alpha, .beta, .gamma, .delta, .epsilon, .zeta {
	font-family:'Montserrat', sans-serif;
	font-weight:700;
}

@media (min-width: 1160px)
{
	.prologue-title {
		font-size:52px;
	}
}
@media (min-width: 977.77778px)
{
	h2 {
		font-size:42px;
	}
}

@media screen and (min-width: 1040px) and (min-width: 850px)
{
	.header-logo {
		display:flex;
		flex-direction:column;
		flex-wrap:nowrap;
		align-self:flex-start;
		margin-bottom:0;
	}
		.header-logo img {
			width:auto; height:54px;
		}

	.search {
		left:224px;
	}
}

@media screen and (min-width: 850px)
{
	.nav-link {
		color:$color-theme_01-02;
	}
	.nav-logout-link, .nav-contact-link {
		color:#FFF;
		background-color:$color-theme_01-03;
	}
	.nav-logout-link:hover, .nav-contact-link:hover {
		background-color:$color-theme_01-02;
	}

	.search-toggle:hover {
		color:$color-theme_01-01;
	}

	.header:not(.header-sticky) .subnav-list {
		background-color:rgba($color-theme_01-02, .9);
	}
	.header:not(.header-sticky) .subnav-link:hover {
		background-color:rgba($color-theme_01-03, .5);
	}
}

@media screen and (min-width: 800px)
{
	.accommodation-header-info {
		background-color:darken($color-theme_01-01, 3%);
	}
	.back:hover {
		color:$color-theme_01-01;
	}
}

@media screen and (max-width: 849px)
{
	.header-threshold {
		padding-top:52px;
	}

	.header-logo img {
		height:32px;
	}

	.booking-close-wrapper,
	.header-ui::before {
		background-color:rgba($color-theme_01-01, .75);
	}
	.booking-container,
	.nav {
		background-color:$color-theme_01-01;
	}

	.booking-btn.booking-btn {
		border-color:$color-theme_01-03;
		background-color:$color-theme_01-03;
	}

	.search-control {
		color:#FFF;
	}
}

@media screen and (max-width: 599px)
{
	.modal-body::before {
		background-color:rgba($color-theme_01-01, .75);
	}
}

@media screen and (max-width: 851px)
{
	.hero,
	.accommodation-gallery {
		height:calc(100vh - 99px);
	}
}

@media screen and (max-width: 799px)
{
	body::before {
		background-color:$color-theme_01-01;
	}
	.accommodation-feature .icon {
		color:$color-theme_01-01;
	}
}

.header-sticky .header-logo img {
	width:auto; height:32px;
}

.btn-ui,
.social,
h1, h2, h3, h4, h5, h6,
.ui-datepicker td span.ui-state-active, .ui-datepicker td a.ui-state-active, .ui-datepicker td button.ui-state-active,
.pagination-prev:hover, .pagination-next:hover,
.pagination-number:hover, .pagination-number.is-active,
.alpha, .beta, .gamma, .delta, .epsilon, .zeta {
	color:$color-theme_01-01;
}

html,
a,
.band-tertiary .social:hover,
.field-control,
.field-datepicker,
.breadcrumbs-btn,
.pagination-number,
.pagination-prev, .pagination-next,
.back {
	color:$color-theme_01-02;
}

a:hover,
.footer-heading,
.band-primary a:not([class]):hover, .band-secondary a:not([class]):hover, .vacancy a:not([class]):hover, .video a:not([class]):hover, .widget a:not([class]):hover,
.product-favourite:hover, .product-favourite.is-favourite,
.post-more {
	color:$color-theme_01-03;
}

@media screen and (min-width: 769px)
{
	a:hover {
		color:$color-theme_01-01;
	}
}

.product,
.product-thumb,
.post-icon,
.proposition.slick-current,
.modal,
.slick-dots button::before,
.widget,
.widget-thumb,
.band-primary,
a.accommodation-header-btn:hover,
.comparison-item,
.video-description,
.video,
.facilities .facilities-header th,
.team-name {
	background-color:$color-theme_01-01;
}

.hero,
.band-tertiary,
.social:hover::before,
:nth-child(3)>.accommodation-header-btn,
.team-bio {
	background-color:$color-theme_01-02;
}

.band-secondary,
.product-favourite-label,
.accommodation-header-btn,
.comparison-item-header,
.comparison-heading {
	background-color:$color-theme_01-03;
}

.offer,
.ajax-calendar::before {
	background-color:rgba($color-theme_01-01, .7);
}

.ui-datepicker {
	background-color:rgba($color-theme_01-02, .9);
}
.ui-datepicker::before {
	border-bottom-color:rgba($color-theme_01-02, .9);
}
.ui-datepicker td span:hover, .ui-datepicker td a:hover, .ui-datepicker td button:hover,
.ui-datepicker-prev:hover, .ui-datepicker-next:hover {
	background-color:rgba($color-theme_01-03, .5);
}

:nth-child(2)>.accommodation-header-btn {
	background-color:darken($color-theme_01-03, 10%);
}

.field-select::after {
	border-right: 2px solid $color-theme_01-02; border-bottom: 2px solid $color-theme_01-02;
}

.btn {
	border-color:$color-theme_01-01;
	background-color:$color-theme_01-01;

	&:hover {
		border-color:$color-theme_01-02;
		background-color:$color-theme_01-02;
	}
}

.btn-secondary {
	border-color:$color-theme_01-03;
	background-color:$color-theme_01-03;
}

.btn-ui {
	border-color:#FFF;
	background-color:#FFF;
}

.btn-ui-ghost {
	border-color:#FFF;
	background-color:transparent;
}
.btn-tertiary-ghost {
	color:$color-theme_01-02;
	border-color:$color-theme_01-02;
	background-color:transparent;
}

.ajax::after {
	border-top-color:$color-theme_01-01;
	border-bottom-color:$color-theme_01-01;
}

.breadcrumbs {
	color:$color-theme_01-02;
	border-bottom-color:$color-theme_01-02;
}
.breadcrumbs-link:hover, .breadcrumbs-btn:hover {
	color:$color-theme_01-01;
}

.product-favourite-label::before {
	border-right-color:$color-theme_01-03;
}

.accordion-toggle {
	background-color:$color-theme_01-01;
}
.accordion:nth-of-type(even) .accordion-toggle {
	background-color:darken($color-theme_01-01, 3%);
}

.facilities th, .facilities td {
	border-color:$color-theme_01-01;
}

/*
.header-sticky .search-toggle {
	margin-left:auto;
}
.header-sticky .search-control {
	padding-top:5px; padding-bottom:5px;
}
*/

.alert-favourite {
	background-color: rgba($color-theme_01-01, .88);
}

.prologue-boat {
	display: none;
}

.error .prologue-windmill {
	display: block !important;

	@media (max-width: 749px) {
		display: none !important;
	}
}

/*
.ui-datepicker td button {
	&.calendar-bookable {
		background-color: $colour-available;
	}
}

.key-available {
	background-color: $colour-available;
}
*/

.ui-datepicker td button {
	&.calendar-bookable {
		background-color: rgba(149,255,1,0.5);
	}
}

.key-available {
	background-color: rgba(149,255,1,0.5);
}
